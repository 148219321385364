<template>
  <section class="invoice-preview-wrapper">
    <div v-if="!isFetchingFormDetails && !validOutput">
      <h1>Le formulaire demandé n'existe pas</h1>
    </div>
    <content-loader
      v-if="isFetchingFormDetails"
      view-box="0 0 400 460"
      :speed="2"
      primary-color="#f3f3f3"
      secondary-color="#ecebeb"
    >
      <circle cx="29" cy="30" r="17" />
      <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
      <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
      <rect x="-7" y="60" rx="2" ry="2" width="408" height="132" />
    </content-loader>
    <div v-if="!isFetchingFormDetails && validOutput" class="main-grid">
      <!-- for print pdf -->
      <vue-html2pdf
        v-if="validOutput"
        ref="html2Pdf"
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="10"
        :filename="'REF-' + validOutput.reference + '-' + selectedForm.title"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        :pdf-margin="10"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        class="d-content"
        @progress="onProgress($event)"
        @hasDownloaded="onDownloaded($event)"
      >
        <section slot="pdf-content">
          <!-- start real content -->
          <b-col cols="12" xl="12" md="12" class="" style="font-size: 11px">
            <b-card no-body class="invoice-preview-card">
              <b-card-body class="invoice-padding pb-0">
                <div
                  class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
                >
                  <div>
                    <AppLogo :width="200" :height="100" />
                  </div>
                  <div class="mt-md-0 d-flex flex-row-reverse">
                    <div
                      v-if="validOutput.qrcode"
                      class="d-flex flex-column"
                      @click="downloadQRCode"
                    >
                      <img :src="validOutput.qrcode" width="120" height="120" />
                    </div>
                    <div class="col-12 text-right mt-1">
                      <small style="font-size: 12px">
                        Référence: <strong> {{ validOutput.reference }}</strong>
                        <br />
                        O1 BP 2037 <br />
                        Cotonou, Bénin <br />
                        Tel : +229 21 30 70 13 <br />
                        +229 21 30 70 10 <br />
                        mtca.sp@gouv.bj
                      </small>
                    </div>
                  </div>
                </div>
              </b-card-body>
              <component
                :is="selectedForm.vueComponents.details"
                :valid-output="validOutput"
              />
            </b-card>
          </b-col>
          <!-- end real content -->
        </section>
      </vue-html2pdf>
      <!-- current content -->
      <div cols="8" xl="9" md="8" class="position-relative">
        <b-card no-body class="invoice-preview-card">
          <b-card-body class="invoice-padding pb-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <div>
                <AppLogo :width="210" :height="110" />
              </div>
              <div class="mt-md-0 d-flex flex-row-reverse">
                <div
                  v-if="validOutput.qrcode"
                  class="d-flex flex-column"
                  @click="downloadQRCode"
                >
                  <img :src="validOutput.qrcode" />
                </div>
                <div class="col-12 text-right mt-1">
                  <small>
                    Référence: <strong> {{ validOutput.reference }}</strong>
                    <br />
                    O1 BP 2037 <br />
                    Cotonou, Bénin <br />
                    Tel : +229 21 30 70 13 <br />
                    +229 21 30 70 10 <br />
                    mtca.sp@gouv.bj
                  </small>
                </div>
              </div>
            </div>
          </b-card-body>
          <component
            :is="selectedForm.vueComponents.details"
            :valid-output="validOutput"
          />
        </b-card>
      </div>

      <!-- sidebar -->
      <div class="options-sidebar">
        <div cols="12" md="4" xl="3" class="sidecard">
          <!-- bouttons -->
          <b-card>
            <div v-if="$can('update', 'formulaireStatistiquesMensuels')">
              <b-button
                v-if="
                  !estUneFicheCliente &&
                  validOutput &&
                  validOutput.statut != 'valide' &&
                  !estUneFicheFrontiere
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :disabled="validOutput && validOutput.statut === 'valide'"
                variant="primary"
                class="mb-75"
                block
                @click="markFormAsValid"
              >
                <b-spinner v-if="isLoading" small />
                <span v-if="!isLoading"> Valider la fiche</span>
              </b-button>
            </div>

            <b-button
              v-if="
                currentFormType.includes('forms-frontiere-entree-voyageurs')
              "
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-toggle.sidebar-send-invoice
              variant="outline-primary"
              class="mb-75"
              block
              @click="downloadPDF()"
            >
              Envoyer par Mail
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mb-75"
              block
              @click="downloadPDF()"
            >
              Télécharger la fiche PDF
            </b-button>
          </b-card>
          <!-- meta data -->
          <b-card class="nopadding">
            <div
              v-if="!estUneFicheCliente && !estUneFicheFrontiere"
              class="d-flex justify-content-between"
            >
              <div>Statut</div>
              <div v-if="validOutput.statut == 'attente'">
                <b-badge pill variant="warning" class="badge-glow">
                  En attente</b-badge
                >
              </div>
              <div v-if="validOutput.statut == 'valide'">
                <b-badge pill variant="success" class="badge-glow">
                  Validé</b-badge
                >
              </div>
              <div v-if="validOutput.statut == 'rejette'">
                <b-badge pill variant="danger" class="badge-glow">
                  Rejetté</b-badge
                >
              </div>
            </div>
            <div class="d-flex justify-content-between mt-1">
              <div>Date Création</div>
              <div>
                <strong>
                  {{ convertDateToLocalString(validOutput.createdAt) }}
                </strong>
              </div>
            </div>
            <div
              v-if="validOutput.auteur"
              class="d-flex justify-content-between mt-1"
            >
              <div>Enquêteur</div>
              <div>
                <strong>
                  {{ validOutput.auteur.firstname }}
                  {{ validOutput.auteur.lastname }}
                </strong>
              </div>
            </div>
          </b-card>
        </div>
      </div>
    </div>

    <invoice-sidebar-send-invoice
      v-if="validOutput"
      :form-data="validOutput"
      :pdf="pdf"
      :current-subject="subject"
    />
    <invoice-sidebar-add-payment />
  </section>
</template>

<script>
import { ref, onUnmounted } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
  BBadge,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapActions } from "vuex";
import { ContentLoader } from "vue-content-loader";
import VueHtml2pdf from "vue-html2pdf";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import router from "@/router";

import AppLogo from "@/components/AppLogo.vue";
import utilsService from "@/services/utils/utils.service";

import invoiceStoreModule from "../invoice/invoiceStoreModule";
import InvoiceSidebarAddPayment from "../invoice/InvoiceSidebarAddPayment.vue";
import InvoiceSidebarSendInvoice from "../invoice/InvoiceSidebarSendInvoice.vue";

import formulaireStoreModule from "@/store/formulaire";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

const DetailsEntreesFrontieresTerrestres = () =>
  import(
    "@/views/apps/formulaires/frontieres-terrestres-entrees/DetailsEntreesFrontieresTerrestres.vue"
  );
const DetailsSortiesFrontieresTerrestres = () =>
  import(
    "@/views/apps/formulaires/frontieres-terrestres-sorties/DetailsSortiesFrontieresTerrestres.vue"
  );
const DetailsAgenceVoyage = () =>
  import("./agence-voyage/DetailsAgenceVoyage.vue");
const DetailsLocation = () => import("./location/DetailsLocation.vue");
const DetailsRestauration = () =>
  import("./restauration/DetailsRestauration.vue");
const DetailsHotel = () => import("./hotel/DetailsHotel.vue");
const DetailsAgenceTransport = () =>
  import("./agence-transport/DetailsAgenceTransport.vue");
const DetailsActivitesCulturelles = () =>
  import("./activites-culturelles/DetailsActivitesCulturelles.vue");
const DetailsAgenceSportive = () =>
  import("./agence-sportive/DetailsAgenceSportive.vue");
const DetailsAgenceCulturelle = () =>
  import("./agence-culturelle/DetailsAgenceCulturelle.vue");
const DetailsHotelClient = () =>
  import("./ficheEnregistrementClient/hotel/DetailsHotelClient.vue");
const DetailsEntreeAeroport = () =>
  import(
    "./ficheEnregistrementClient/entree-aeroport/DetailsEntreeAeroport.vue"
  );
const DetailsSortieAeroport = () =>
  import(
    "./ficheEnregistrementClient/sortie-aeroport/DetailsSortieAeroport.vue"
  );
const DetailsFicheTourismeInterne = () =>
  import("./tourisme-interne/DetailsFicheTourismeInterne.vue");
const DetailsFicheTourismeEmetteur = () =>
  import("./tourisme-emetteur/DetailsFicheTourismeEmetteur.vue");
const DetailsFicheTourismeRecepteur = () =>
  import("./tourisme-recepteur/DetailsFicheTourismeRecepteur.vue");

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BBadge,
    BSpinner,

    InvoiceSidebarAddPayment,
    InvoiceSidebarSendInvoice,
    DetailsAgenceVoyage,
    DetailsLocation,
    DetailsRestauration,
    DetailsHotel,
    DetailsAgenceTransport,
    DetailsActivitesCulturelles,
    DetailsAgenceSportive,
    DetailsAgenceCulturelle,
    DetailsHotelClient,
    DetailsEntreeAeroport,
    DetailsSortieAeroport,
    DetailsFicheTourismeInterne,
    DetailsFicheTourismeEmetteur,
    DetailsFicheTourismeRecepteur,
    AppLogo,

    ContentLoader,
    VueHtml2pdf,
    DetailsEntreesFrontieresTerrestres,
    DetailsSortiesFrontieresTerrestres,
  },
  setup() {
    const requiredStoreModules = [
      { path: "formulaire", module: formulaireStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },

  data() {
    const { currentUserId } = utilsService.currentUserUtils();
    return {
      isLoading: false,
      isLoadingValideStat: false,
      invalidFormInput: false,
      formType: "forms-stats-agences",
      formId: this.$route.params.id,
      isFetchingFormDetails: false,
      validOutput: null,
      currentModel: "",
      pdf: null,
      canDownload: false,
      currentFormId: "",
      currentModel: "",

      selectedForm: null,
      formsWithSendByMail: ["forms-frontiere-entree-voyageurs"],
      subject: "",

      currentUserId,
    };
  },
  computed: {
    estUneFicheCliente() {
      return this.selectedForm && this.selectedForm.type === "client";
    },
    estUneFicheFrontiere() {
      return this.selectedForm && this.selectedForm.sousType === "frontiere";
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        this.isFetchingFormDetails = true;

        const { model, id } = this.$route.params;

        this.currentFormType = model;

        if (!id || !model) {
          this.validOutput = null;
          this.isFetchingFormDetails = true;
          return;
        }

        this.currentFormId = id;
        this.currentFormType = model;

        this.selectedForm = utilsService.getFormulaireByModel(
          this.currentFormType
        );

        if (
          !this.selectedForm ||
          !this.$can("read", this.selectedForm.resource)
        ) {
          this.$router.push("/");
        }

        this.loadData();
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  mounted() {},
  methods: {
    ...mapActions("formulaire", {
      getFormulaire: "getFormulaire",
      action_updateFormulaire: "updateFormulaire",
    }),
    loadData() {
      this.getFormulaire({
        formType: this.currentFormType,
        id: this.currentFormId,
      })
        .then((result) => {
          this.validOutput = result.data.resource;
          if (this.currentFormType === "forms-frontiere-entree-voyageurs") {
            this.subject = ` FICHE DE RENSEIGNEMENT AUX FRONTIERES TERRESTRES (ENTREE) ${this.validOutput.firstname} ${this.validOutput.lastname}`;
          }
          this.isFetchingFormDetails = false;
        })
        .catch((err) => {
          this.validOutput = null;
          this.isFetchingFormDetails = false;
        });
    },
    markFormAsValid() {
      this.isLoading = true;
      const data = {
        formType: this.currentFormType,
        id: this.currentFormId,
        data: { statut: "valide" },
      };
      this.action_updateFormulaire(data)
        .then((res) => {
          this.validOutput.statut = "valide";
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Opération réussie",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.message || "Une erreur est survenue",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          console.log(error);
        });
    },
    editCurrentItem() {
      const data = { ...this.validOutput };
      delete data.__v;
      delete data.createdAt;
      delete data.updatedAt;
      delete data.reference;
      delete data.qrcode;
      delete data.id;

      if (!this.currentFormType || !this.selectedForm) return;
      this.$router.push({
        name: "formulaires-preview",
        params: {
          model: this.currentFormType,
          data,
          preview: true,
          isEditionOperation: true,
          slug: this.selectedForm && this.selectedForm.slug,
        },
      });
    },
    printInvoice() {
      window.print();
    },
    async downloadQRCode() {
      if (this.validOutput) {
        const image = await fetch(this.validOutput.qrcode);
        const imageBlog = await image.blob();
        const imageURL = URL.createObjectURL(imageBlog);

        const link = document.createElement("a");
        link.href = imageURL;
        link.download =
          this.validOutput.nomEtablissement +
          this.validOutput.mois +
          this.validOutput.annee;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    onProgress(event) {
      // console.log(`Processed: ${event} / 100`)
    },
    onDownloaded(event) {
      this.pdf = event;
    },
    downloadPDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    validateData() {
      this.isLoadingValideStat = true;
      const data = {
        formType: this.currentFormType,
        id: this.currentFormId,
        data: { confirmeForStat: this.currentUserId },
      };
      this.action_updateFormulaire(data)
        .then((res) => {
          this.validOutput.confirmeForStat = this.currentUserId;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Opération réussie",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.isLoadingValideStat = false;
        })
        .catch((error) => {
          this.isLoadingValideStat = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.message || "Une erreur est survenue",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/base/pages/app-invoice.scss";

.main-grid {
  display: grid;
  grid-template-columns: auto 400px;
  column-gap: 30px;
  position: relative;
  .options-sidebar {
    overflow: auto;
    height: 80vh;
    padding-bottom: 59px;
    position: fixed;
    right: 1.5rem;
    width: 400px;
  }
}
// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
  .main-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    row-gap: 30px;
    position: relative;
    .options-sidebar {
      overflow: auto;
      height: unset;
      position: unset;
      width: unset;
    }
  }
}
.d-content {
  display: contents;
}
#sidebar-wrapper {
  position: fixed;
  right: 250px;
  width: 100%;
  height: 100%;
  margin-right: -90%;
  overflow-y: auto;
  .sidecard {
    margin: 0 0 800px 0;
  }
}

@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
